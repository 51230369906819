
              @import "@/assets/css/variables.scss";
            






































































// @import '@/assets/css/components/register/accountConfiguration.scss';

.el-form.account_config {
  max-width: 800px;

  .platform_img {
    width: 94px;
    height: 18px;
  }

  .tnc_box {
    .el-form-item__content {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .el-checkbox {
        width: auto;
      }

      .tnc {
        font-size: 14px;
        line-height: 22px;
        color: $text-primary;
        @include rtl-sass-prop(margin-left, margin-right, 8px);
        cursor: pointer;

        span {
          color: $text-link;
          @include rtl-sass-prop(margin-left, margin-right, 4px);
        }
      }
    }
  }
}

.account_config_tnc {
  li {
    font-size: 14px;
    line-height: 22px;
    color: $text-primary;

    a {
      color: $text-link;
    }
  }
}
