
              @import "@/assets/css/variables.scss";
            








































































































// @import '@/assets/css/components/vUpload.scss';
/deep/ {
  .el-upload {
    width: 100%;
    padding: 82px 0;
    border: 1px dashed #DFE4EB;
    border-radius: 4px;
    background-color: #F8F9FC;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .upload-demo {

    .svg-icon {
      font-size: 32px;
    }

    .upload_btn {
      font-size: 12px;
      color: #7A8699;
      line-height: 18px;
      margin-top: 4px;
    }
  }
}
